/// General layout
.container {
  margin: 0 auto;
  max-width: 1400px;
  width: 90%;

  &-small{
    max-width: 900px;
    width: 90%;
      margin: 0 auto;
  }

  &-wide{
    max-width: 1800px;
    width: 90%;
      margin: 0 auto;
  }
  &-menu{
    max-width: 1400px;
    width: 80%;
      margin: 0 auto;
  }
}

.contact-container {
  position: relative;
  top:-100px;
  z-index:1;
}

.module{
  position: relative;
  z-index: 2;
}

.homepage_sections .module:nth-child(2){
    @media (min-width: 1024px){
  z-index: 1;
}
}

.h-half{
  height: 65vh;
}

// Sections
.section {
  @include clearfix;

}

/**
 * Main content containers
 * 1. Make the container full-width with a maximum width
 * 2. Center it in the viewport
 * 3. Leave some space on the edges, especially valuable on small screens
 */

     @media screen and (max-width: 768px){
.inner {
  max-width: $max-width; /* 1 */
  padding-left: $pad; /* 3 */
  padding-right: $pad; /* 3 */
  width: 93%; /* 1 */
}
}
.inner-text{

}

/// Grids
// inspiered bay: https://github.com/paulcpederson/inline-block-grid
/* Usage:
  <div class="grid">
  <div class="col-3">
    ... stuff
    </div>
    <div class="col-9">
    ... more stuff
    </div>
  </div>
*/
.grid {
  text-align: left;
  display: block;
  &.align-center { text-align: center; }
  &.align-right  { text-align: right; }
  &.align-left  { text-align: left; }
  position: relative;
  &--gutter {
    margin-left: -$gutter;
    margin-right: -$gutter;
  }
}


@function col ($i) {
  @return (($i / $col-count) * 100%);
}

@for $n from 1 through $col-count {
  .col-#{$n} { width: col($n); }
  .push-#{$n}    { margin-left: calc(#{col($n)} - 4px); }
  .pull-#{$n}   { margin-right: calc(#{col($n)} - 4px); }
}

@include respond-to('wide') {
  @for $i from 1 through $col-count {
    .wide-col-#{$i} { width: col($i); }
  }
  @for $i from 0 through $col-count {
    .wide-push-#{$i}  { margin-left: col($i); }
    .wide-pull-#{$i} { margin-right: col($i); }
  }
}

@include respond-to('medium') {
  @for $i from 1 through $col-count {
    .medium-col-#{$i} { width: col($i); }
  }
  @for $i from 0 through $col-count {
    .medium-push-#{$i}  { margin-left: col($i); }
    .medium-pull-#{$i} { margin-right: col($i); }
  }
}

@include respond-to('small') {
  @for $i from 1 through $col-count {
    .small-col-#{$i} { width: col($i); }
  }
  @for $i from 0 through $col-count {
    .small-push-#{$i}  { margin-left: col($i); }
    .small-pull-#{$i} { margin-right: col($i); }
  }
}

@include respond-to('mob') {
  @for $i from 1 through $col-count {
    .mob-col-#{$i} { width: col($i); }
  }
  @for $i from 0 through $col-count {
    .mob-push-#{$i}  { margin-left: col($i); }
    .mob-pull-#{$i} { margin-right: col($i); }
  }
}
