.recent-blog{
  min-height: 400px;
  position: relative;
  z-index: 1;

  &:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
  }
}
