/**
 * Basic typography style for copy text
 */
 // FONT CLASSES

 @import url(https://fonts.googleapis.com/css?family=Montserrat);

%font-sans-regular {
  font-family: $regular-font-stack;
  font-weight: 300;
  font-style: normal;
  @include alias;
}

%font-sans-light {
  font-family: $regular-font-stack;
  font-weight: 200;
  font-style: normal;
  @include alias;
}

%font-sans-semi-bold {
  font-family: $regular-font-stack;
  font-weight: 600;
  font-style: normal;
  @include alias;
}

%font-sans-bold {
  font-family: $regular-font-stack;
  font-weight: 700;
  font-style: normal;
  @include alias;
}

%font-alt-regular {
  font-family: $alt-font-stack;
  font-style: normal;
  font-weight: 400;
  @include alias;
}

%font-alt-bold {
  font-family: $alt-font-stack;
  font-style: normal;
  font-weight: 700;
  @include alias;
}

body {
  background: #fff;
  color: $text-color;
  @extend %font-sans-regular;
  @include font-values(24,27);
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 1rem;
  hyphens: none;
}
h1 {
  @extend %font-sans-bold;
  @include fluid-type(48px, 92px);
  text-transform: uppercase;
  font-weight:800;
  color: #000;
  line-height: 1;




            @media screen and (max-width: 1023px) {
font-size: 40px;
}
b, strong{
  font-weight:800;
font-weight:800;
  -webkit-text-stroke: 1px black;
color: white;
text-shadow:
    1px 1px 0 #000,
  -1px -1px 0 #000,
   1px -1px 0 #000,
   -1px 1px 0 #000,
    1px 1px 0 #000;
}
}

.super-text {
    @extend %font-sans-bold;
  @include fluid-type(70px, 120px);
  text-transform: uppercase;;
color: transparent;
svg{
  -webkit-filter: drop-shadow( 0px 0px 2px  rgba(255,0,0,1));
filter: drop-shadow( 0px 0px 2px  rgba(255,0,0,1));
}
    &.white{
      opacity: 0.2;
      -webkit-text-stroke: 1px black;
    color: #000;
    text-shadow:
        1px 1px 0 #fff,
      -1px -1px 0 #fff,
       1px -1px 0 #fff,
       -1px 1px 0 #fff,
        1px 1px 0 #fff;
    }
}

.icon{
  -webkit-filter: drop-shadow( 0px 0px 2px  rgba(255,0,0,1));
filter: drop-shadow( 0px 0px 2px  rgba(255,0,0,1));
}

.banner{
  h1{
    margin-top: 50px;
  }
}

h2 {
  @extend %font-sans-regular;
  @include fluid-type(24px, 34px);
  line-height: 1.4;

}
h3 {
  @extend %font-sans-bold;
  @include fluid-type(20px, 20px);
line-height: 1.4;
}

h4 {
    @extend %font-sans-semi-bold;
  @include font-values(14,14);
  margin: 0;
}

h5 {
  @extend %font-sans-regular;
@include font-values(14,14);
margin: 0;
letter-spacing: 2px;
color: #fff;
}

@media screen and (max-width:640px){
  .point h5 {
    font-size: 14px !important;
  }
}

h6{
  @extend %font-sans-regular;
  @include fluid-type(14px, 16px);
  line-height: 1.2;
  text-transform: uppercase;
  color: #fff;
  margin: 0;
}

.small {
  @include font-values(14,17);
}
.medium {
  @include font-values(16,18);
  line-height: 1.3;
}
.big {
  @include font-values(24,27);
  line-height: 1.3;
}
.huge {
  @include font-values(27,30);
  line-height: 1.3;
}
p {
  @include fluid-type(14px, 16px);
  margin-top: 0;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 0.7rem;

  b, strong{
    color: #222427;
  }


}

.text-white p{
  color: #fff;
}


footer p{
    @include fluid-type(12px, 14px);
  color: #fff;
  b, strong{
      color: #fff;
  }
}

footer a{
    color: #fff;
}
.page-description p{
    @include fluid-type(14px, 20px);
    font-weight: 500;
}

ul {
  padding-left: 15px;
}
strong {
  font-weight: bold;
}
// Typography helpers
.bold {
  @extend %font-alt-bold;
}
.sans {
  @extend %font-sans-regular;
}
.serif {
  @extend %font-alt-regular;
}
.upper {
  text-transform: uppercase;
}

.text-set-white h4{
  color:#fff;
}


.text-set-white strong {
  color:#fff;
}

button, input, select, textarea {
  font-size: 1rem !important;
}
