// -----------------------------------------------------------------------------
// This file contains all styles related to the footer of the site/application.
// -----------------------------------------------------------------------------

.footer {
  padding: $pad;
}

footer {
  .tabs {
    font-size: 14px;
    font-weight: 600;
  }

  ul {
    padding: 0px;
  li{
    list-style-type: none;
  }
}
}

.block-contact p{
  font-size: 16px;
  font-weight: 200;
}

@media screen and (max-width:400px){
.legal-footer{
  display: block;
}
}
