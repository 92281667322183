// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site/application.
// -----------------------------------------------------------------------------
.h-half{
    @media screen and (max-width:$sm) {
      height:50vh;
    }
}

#menu-center{
  background-color: rgba(0, 0, 0, 0.7)
}

#header{
  nav{
    transition: 0.3s;
    .logo{
      transition: 0.3s;
      img{
        transition: 0.3s;
      }
    }
  }
  &.scrolled nav{
    height: 40px;
    transition: 0.3s;
      background-color: rgba(0, 0, 0, 1);
    .logo{
        margin-bottom: -28px;
        bottom: 0 !important;
        transition: 0.3s;
      img{
        width: 90px;
        transition: 0.3s;

      }
    }

  }
}
.header {
  background: transparent;
  width: 100%;
  position: relative;
  background-position: center;
  background-size: cover;


  &__no-bg {
    background-color: $dark-bg;
    overflow: visible;
  }

  &__login {
    overflow: visible;
    @media screen and (max-width:$sm) {
      background-color: $brand-color;
    }
  }


  h1 {
    @media screen and (max-width:$md) {
      font-size:42px;
    }
    @media screen and (max-width:$sm) {
      font-size:36px;
    }
  }
}

.header-menu {
  ul {
    li {
      @media screen and (max-width:$sm) {
        font-size: 14px;
      }
    }
  }
  .block-contact p{
  @media screen and (max-width:$sm) {
    font-size: 14px;
  }
}
}

.header__flex {
  display: flex;
  flex-wrap: no-wrap;
  justify-content: space-between;
  align-items: center;
}

.full-menu {
  @media screen and (max-width:$sm) {
    height: calc(var(--vh, 1vh) * 100) !important;
  }
}

.header__item {
  display: block;
}

.logo {
  position: relative;
  z-index: 40;
}

.mob_link {
  border: 1px solid #fff;
  padding: 10px 8px;
  border-radius: 3px;
  text-align: center;
}

.mobile-main-btns .mob_link:nth-child(1) {
  width: 100%;
}

.mobile-main-btns .mob_link:nth-child(2) {
  width: calc(50% - 0.5em);
  margin-right: 0.5em;
}

.mobile-main-btns .mob_link:nth-child(3) {
  width: calc(50% - 0.5em);
  margin-left: 0.5em;
}
//
// .banner {
//   height: calc(var(--vh, 1vh) * 70);
// }

.banner {
  position: relative;
  z-index: 1;

  &:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    background: rgb(5,17,33);
    background: linear-gradient(127deg, rgba(5,17,33,0.623686974789916) 32%, rgba(5,17,33,0) 67%);
  }

  &:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    background: rgb(5,17,33);
    background: linear-gradient(12deg, rgba(5,17,33,0) 57%, rgba(5,17,33,0.49763655462184875) 100%);
  }
}
