// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// -----------------------------------------------------------------------------

form {
  margin-top: $pad;

  p {
    display: none;
  }
}

#form-input-amount {
  border: none;
  color: #1875BC;
  font-weight: 500;
  position: relative;

  &:before {
    content: "$";
    top: 0;
    bottom: 0;
    position: absolute;
    left: 0;
    right: 0;
    width: 20px;
    z-index: 1000;
    height: 20px;
    background: red;
    display: block;
    margin: auto;
  }
}

  fieldset {
    border: none;
    margin-top: $pad;
    padding: 0;
    text-align: left;

    legend {
      @extend %font-sans-bold;
    }
  }

  legend {
    display: none;
  }

  .field {
    margin-bottom: $pad;

    input[type=email],
    input[type=number],
    input[type=text],
    select,
    textarea {
      border: 1px solid $text-color;
      padding: $pad/2;
      width: 100%;
    }

    input,
    textarea {
      border-radius: 0;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      color: $dark-grey;
      font-size: 14px;
    }

    textarea {
      height: 150px;
    }

    select {
      border: 0;
      border: 1px solid $text-color;
      appearance: none;
      -moz-appearance: none;
      /* Firefox */
      -webkit-appearance: none;
      /* Safari and Chrome */
    }
  }


  textarea {
    &:focus {
      outline: none;
    }
  }

  input {
    &:focus {
      outline: none;
    }
  }

  button {
    background: $dark-grey;
    border: 1px solid $brand-color;
    color: #fff;
    margin: 0 auto;
    padding: $pad/2;
    width: 100%;
    text-transform: uppercase;

    &:hover {
      background: $brand-color;
    }
  }

  .input {
    @extend %font-sans-regular;
    @include font-values(14,14);
    background-color: $pale-grey;
  }

  .input.required {
      font-weight: 600;
  }

  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  main,
  nav,
  section,
  summary {
    display: block;
  }

  audio,
  canvas,
  video {
    display: inline-block;
  }

  audio:not([controls]) {
    display: none;
    height: 0;
  }

  [hidden] {
    display: none;
  }

  html {
    font-family: sans-serif;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
  }

  body {
    margin: 0;
  }

  a:focus {
    outline: thin dotted;
  }

  a:active,
  a:hover {
    outline: 0;
  }

  abbr[title] {
    border-bottom: 1px dotted;
  }

  b,
  strong {
    font-weight: bold;
  }

  dfn {
    font-style: italic;
  }

  hr {
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    height: 0;
  }

  mark {
    background: #ff0;
    color: #000;
  }

  code,
  kbd,
  pre,
  samp {
    font-family: monospace,serif;
    font-size: 1em;
  }

  pre {
    white-space: pre-wrap;
  }

  q {
    quotes: "\201C" "\201D" "\2018" "\2019";
  }

  small {
    font-size: 80%;
  }

  sub,
  sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
  }

  sup {
    top: -0.5em;
  }

  sub {
    bottom: -0.25em;
  }

  img {
    border: 0;
  }

  svg:not(:root) {
    overflow: hidden;
  }

  figure {
    margin: 0;
  }

  fieldset {
    border: 1px solid #c0c0c0;
    margin: 0 2px;
    padding: 0.35em 0.625em 0.75em;
  }

  legend {
    border: 0;
    padding: 0;
  }

  button,
  input,
  select,
  textarea {
    font-family: inherit;
    font-size: 100%;
    margin: 0;
  }

  button,
  input {
    line-height: normal;
  }

  button,
  select {
    text-transform: none;
  }

  button,
  html input[type="button"],
  input[type="reset"],
  input[type="submit"] {
    -webkit-appearance: button;
    cursor: pointer;
  }

  button[disabled],
  html input[disabled] {
    cursor: default;
  }

  input[type="checkbox"],
  input[type="radio"] {
    box-sizing: border-box;
    padding: 0;
  }

  input[type="search"] {
    -webkit-appearance: textfield;
    -moz-box-sizing: content-box;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
  }

  input[type="search"]::-webkit-search-cancel-button,
  input[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  button::-moz-focus-inner,
  input::-moz-focus-inner {
    border: 0;
    padding: 0;
  }

  textarea {
    overflow: auto;
    vertical-align: top;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
