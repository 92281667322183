// General stuff
img {
  height: auto;
  max-width: 100%;
  width: 100%;
}


.primary-bg{
  background-color: $brand-color;
}

.brand-text{
  color: $brand-color;
}
