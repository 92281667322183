.slider-bottom {
  margin-bottom: -180px;
  @media (max-width: $md) {
    margin-bottom: -100px;
  }
}

.hooper-list:focus{
  outline: none;
}
.hooper-indicators {
  li {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.hooper-indicator {
  width: 8px;
  height: 8px;
  border-radius: 12px;
  margin: 0 8px;
    transition: 0.4s;
    &:focus{
      outline: none;
    }
}

.hooper-indicator.is-active,
.hooper-indicator:hover {
  background-color: #fff;
  width: 16px;
  height: 16px;
  transition: 0.4s;
}

.hooper-pagination {
  background: transparent;
  padding: 10px 10px;
right: 75%;

@media (max-width: $md){
  right: 50%;
top: 365px;
bottom: inherit;
}

@media (max-width: $sm){
  right: 50%;
top: 265px;
bottom: inherit;
}

}

.hooper-navigation button:hover{
  background-color: transparent;
}
.hooper-navigation button:focus{
  outline: none;
}

.hooper-next, .hooper-prev{
  width: 60px;
  padding: 0;

  svg{
    fill:#fff;
    width: 60px;
    height: 60px;
  }
}

@media (max-width: $md){
.hooper-navigation {
  top:40%;
  bottom: inherit;
}
}
