#fp-nav ul li a span, .fp-slidesNav ul li a span{
  background: #fff !important;
}

#burgers{
  @media screen and (min-width:900px){
      min-height: 780px;
  }

}

.swiper-slide{
  .burger-title{
    position: relative;
    top:80px;
    opacity: 0;
          transition: 0.8s;
  }
.burger-image{
  top:80px;
  opacity: 0;
  transition-delay: 0.2s;
        transition-duration: 0.8s;
}
  &.swiper-slide-visible{
    .burger-title{
      position: relative;
      top:0px;
      transition: 0.8s;
      opacity:1;
    }
    .burger-image{
    top:0px;
      transition-delay: 0.2s;
      transition-duration: margin 0.8s;
          transition-duration: opacity 0.1s;
      opacity:1;
    }
  }
}

@media screen and (min-width:1024px){
.burger-contain{
  height:40vh
}
}

.burger-wrapper{
  min-height: 800px;
}

.burger{

  @media screen and (max-width:1024px){
  background-size: 150% !important
}
@media screen and (max-width:600px){
background-size: 180% !important
}
}

@media screen and (max-width:1023px){
.super-text{
  position: relative !important;
}

}

@media screen and (max-width:768px){
.burger-image{
  max-height: 400px;
  background-position: 50% 75%;
}
.burger-wrapper{
  min-height: 500px;

  .burger-image{
    margin-top: -2rem !important;
  }
}
}
@media screen and (min-width:1024px){
.hv-50{
  height: 50vh
}
.menu{
  min-height: 100vh
}
}
.burger img{
  min-width:900px;
}
/* The version of Bourbon used in this Pen was 4.* */

#header a{
  color: #fff;
&.active{
  h4{
  border-bottom: solid 2px;
}
}
}

.top-nav {
  background-color: black;
  display: flex;
  align-items: center;
}

.top-nav p {
  position: absolute;
  left: 20px;
  top: 8px;
  color: #666;
  font-size: 14px;
}

.burger-menu {
  color: #60635E;
  text-transform: uppercase;
  z-index: 100;

  &.is-open {
    z-index: 1010;
  }
}

.burger-click-region {
  position: relative;

  width: 40px;
  height: 30px;
  cursor: pointer;
}

.burger-menu-txt {
  display: block;
  position: absolute;
  left: 0;
  top: 5px;
  cursor: default;
  transition: opacity 200ms ease-out, transform 200ms cubic-bezier(.34, .55, .25, .83);

}

$menu-animation-duration: 400ms;
$menu-animation-timing:   ease-out;

.burger-menu-piece {
  display: block;
  position: absolute;
  width: 40px;
  border-top: 3px solid #fff;
  transform-origin: 50% 50%;
  transition: transform $menu-animation-duration $menu-animation-timing;

  &:nth-child(1) {
    top: 0;
  }

  &:nth-child(2) {
    top: 12px;
    opacity: 1;
    transition: transform $menu-animation-duration $menu-animation-timing, opacity 0ms linear $menu-animation-duration / 2;
  }

  &:nth-child(3) {
    top: 24px;
  }

  .active & {

      &:nth-child(1) {
        animation: burger-open-top $menu-animation-duration $menu-animation-timing forwards;
      }

      &:nth-child(2) {
        opacity: 0;
        transition: transform $menu-animation-duration $menu-animation-timing, opacity 0ms linear $menu-animation-duration / 2;
      }

      &:nth-child(3) {
        animation: burger-open-bot $menu-animation-duration $menu-animation-timing forwards;
      }
  }

  .closing & {
    &:nth-child(1) {
      animation: burger-close-top $menu-animation-duration $menu-animation-timing forwards;
    }

    &:nth-child(3) {
      animation: burger-close-bot $menu-animation-duration $menu-animation-timing forwards;
    }
  }
}

@keyframes burger-open-top {
  50% {
    transform: translate3d(0, 12px, 0);
  }
  100% {
    transform: translate3d(0, 12px, 0) rotate(45deg);
  }
}

@keyframes burger-open-bot {
  50% {
    transform: translate3d(0, -12px, 0);
  }
  100% {
    transform: translate3d(0, -12px, 0) rotate(-45deg);
  }
}

@keyframes burger-close-top {
  0% {
    transform: translate3d(0, 12px, 0) rotate(45deg);
  }
  50% {
    transform: translate3d(0, 12px, 0) rotate(0deg);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes burger-close-bot {
  0% {
    transform: translate3d(0, -12px, 0) rotate(-45deg);
  }
  50% {
    transform: translate3d(0, -12px, 0) rotate(0deg);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}

.ks-modal {
  position: absolute;
  left: 0;
      top: 0;
      width: 50%;
      height: 50%;

  background-color: rgba(0, 0, 0, 0.8);
  opacity: 0;
  visibility: hidden;
  text-align: left;
  z-index: 49;
  transition: opacity 500ms ease-out, visibility 0 linear 500ms;

  &.open {
    opacity: 1;
    visibility: visible;
    transition: opacity 500ms ease-out, visibility 0 linear 0;
  }
}

.ks-modal-contents {
  display: none;
}

.site-nav-list {
  position: absolute;
  top: 30%;
  list-style: none;
  width: 100%;
  list-style-type: none;
  padding: 0;
  margin: 0;

  .mobile-item {
    $menu-item-1-delay: 140ms;
    $menu-item-2-delay: 100ms;
    $menu-item-3-delay: 70ms;
    $menu-item-4-delay: 50ms;

    margin: 20px 0;
    opacity: 0;
    transform: translate3d(0, 50px, 0);
    text-decoration: none;

    &:nth-child(1) {
      transition: opacity 250ms ease-out $menu-item-1-delay, transform 250ms ease-in $menu-item-1-delay;
    }

    &:nth-child(2) {
      transition: opacity 250ms ease-out $menu-item-2-delay, transform 250ms ease-in $menu-item-2-delay;
    }

    &:nth-child(3) {
      transition: opacity 250ms ease-out $menu-item-3-delay, transform 250ms ease-in $menu-item-3-delay;
    }

    &:nth-child(4) {
      transition: opacity 500ms ease-out $menu-item-4-delay, transform 400ms ease-out $menu-item-4-delay;
    }
  }

.mobile-item a {
    display: block;
    color: white;
    font-size: 32px;
    padding-left: 20px;
    text-transform: uppercase;
    margin-right: 20px;
    text-decoration: none;
    transition: color 200ms ease-in;

    &:hover {
    }
  }
}

.ks-modal.open {
  .site-nav-list .mobile-item {
    $menu-item-1-delay: 250ms;
    $menu-item-2-delay: 270ms;
    $menu-item-3-delay: 300ms;
    $menu-item-4-delay: 340ms;

    opacity: 1;
    transform: translate3d(0, 0, 0);

    &:nth-child(1) {
      transition: opacity 500ms ease-out $menu-item-1-delay, transform 400ms ease-out $menu-item-1-delay;
    }

    &:nth-child(2) {
      transition: opacity 500ms ease-out $menu-item-2-delay, transform 400ms ease-out $menu-item-2-delay;
    }

    &:nth-child(3) {
      transition: opacity 500ms ease-out $menu-item-3-delay, transform 400ms ease-out $menu-item-3-delay;
    }

    &:nth-child(4) {
      transition: opacity 500ms ease-out $menu-item-4-delay, transform 400ms ease-out $menu-item-4-delay;
    }
  }
}
