.pagination {

  ul {
    li {
      color: $brand-color;
      width: 30px;
      height: 30px;

      &.current {
        border-color: $brand-color;
        border-radius: 50px;
      }

      &.next-page, &.prev-page{
        width: max-content;

        }
      }
    }
  }
